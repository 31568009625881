<template>
  <GeneralCategory
    :return-route="adminRoute"
    :category-name="categoryName"
    :rest-end-point="restEndPoint"
  />
</template>

<script>
import GeneralCategory from '../generalCategories/GeneralCategories'
import nurseryDefaults from './constants'

export default {
  name: 'AdminNurseryCategories',
  components: {
    GeneralCategory
  },
  data () {
    return { ...nurseryDefaults }
  }
}
</script>
